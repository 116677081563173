import type { Data } from 'stores/builder/field/types';

export const validatePlaceholder = (
    allFieldsSelectOptions: Array<{
        label: string;
        value: string;
    }>,
    isUpdatingField: boolean,
    value: string,
    selectedField?: Data
) => {
    let allFields = [...allFieldsSelectOptions];

    if (isUpdatingField) {
        allFields = allFields.filter(item => item.label.trim() !== selectedField?.placeholder?.trim());
    }

    return allFields.some(item => item.label.trim() === value.trim());
};
