import type { QuestionnaireItem } from 'stores/builder/questionnaire/types';
import type { SingleValue } from 'react-select';
import type { EditorState } from 'draft-js';
import type { FC } from 'types/react';

import { addButtonEntityFromSelection } from 'components/builder/RichTextEditor/helpers/add-button-entity-from-selection';
import { addButtonEntityFromScratch } from 'components/builder/RichTextEditor/helpers/add-button-entity-from-scratch';
import { setDescriptionEditorStateEvent, $descriptionEditorState } from 'stores/builder/descriptionEditor/model';
import { updateButtonEntity } from 'components/builder/RichTextEditor/helpers/update-button-entity';
import { type ChangeEvent, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { setEditorStateEvent, $editorState } from 'components/builder/TextEditor/model';
import { RichTextEditor } from 'components/builder/RichTextEditor/RichTextEditor';
import { type SelectValue, Select } from 'ui-kit/components/Select/Select';
import { $currentFields, $allFields } from 'stores/builder/field/model';
import { isEllipsisActive } from 'utils/is-ellipsis-active';
import { Button } from 'ui-kit/components/buttons/Button';
import { useSelectionText } from 'hooks/useSelectionText';
import { FieldTypesEnum } from 'constants/builder/enums';
import { Input } from 'ui-kit/components/inputs/Input';
import { fieldModalStore } from 'stores/modals/model';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { stateToHTML } from 'draft-js-export-html';
import { Modal } from 'ui-kit/components/Modal';
import { CloseIcon } from 'ui-kit/assets';
import { useUnit } from 'effector-react';
import { v4 as generateId } from 'uuid';

import {
    AllFieldsSelectWrapper,
    CloseIconWrapper,
    SelectWrapper,
    NewFieldText,
    Container,
    Content,
    Footer,
    Label,
    Title,
    Hr
} from './styles';
import {
    setIsAddingFromExistingFieldEvent,
    setPlaceholderEvent,
    setQuestionEvent,
    $placeholder,
    $question
} from './model';
import { SelectMultiSelectRadioEditor, type AddOrRemoveButtonProps } from './SelectMultiSelectRadioEditor';
import { createFieldFromExistingField } from './helpers/create-field-from-existing-field';
import { createFieldForQuestionnaire } from './helpers/create-field-for-questionnaire';
import { getPlaceholderInputError } from './helpers/get-placeholder-input-error';
import { createMultiselectField } from './helpers/create-multiselect-field';
import { validatePlaceholder } from './helpers/validate-placeholder';
import { getFieldType } from './helpers/get-field-and-item-types';
import { createSelectField } from './helpers/create-select-field';
import { createRadioField } from './helpers/create-radio-field';
import { createTextField } from './helpers/create-text-field';
import { inputTypesForField, icons } from './constants';

interface FieldItem {
    type: FieldTypesEnum;
    value: string;
    text: string;
    id: string;
}

export const FieldModal: FC = () => {
    const { closeModal, modal } = fieldModalStore;
    const [
        [_, { placeholder: passedPlaceholder, updateFieldFromQuestionnaire, selectedField, contentId }],
        descriptionEditorState,
        commonCurrentFields,
        question,
        placeholder,
        commonEditorState,
        allFields
    ] = useUnit([modal, $descriptionEditorState, $currentFields, $question, $placeholder, $editorState, $allFields]);
    const editorState = commonEditorState[contentId];
    const currentFields = commonCurrentFields[contentId];
    const selectionText = useSelectionText(editorState);

    const isAddingFieldFromScratch = selectionText.length === 0 && selectedField === undefined;
    const isAddingFieldFromSelection = selectionText.length > 0 && selectedField === undefined;
    const isUpdatingField = selectedField !== undefined;

    const [multiSelectItems, setMultiSelectItems] = useState<FieldItem[]>(
        selectedField?.multiSelectItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );
    const [singleSelectItems, setSingleSelectItems] = useState<FieldItem[]>(
        selectedField?.singleSelectItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );
    const [radioItems, setRadioItems] = useState<FieldItem[]>(
        selectedField?.singleRadioItems || [{ type: FieldTypesEnum.Text, id: generateId(), value: '', text: '' }]
    );
    const [selectedValue, setSelectedValue] = useState<FieldTypesEnum>(selectedField?.type || FieldTypesEnum.Text);
    const [existingField, setExistingField] = useState<QuestionnaireItem | null>(null);
    const allFieldsSelectOptions = useMemo(
        () =>
            allFields.map(item => ({
                label: item.title,
                value: item.id
            })),
        [allFields]
    );
    const [hasPlaceholderError, setPlaceholderError] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    const handleSelectChange = (value: string) => {
        setSelectedValue(value as FieldTypesEnum);
    };

    const handleOnClose = () => {
        closeModal();
    };

    const onDescriptionEditorStateChange = (editorState: EditorState) => {
        setDescriptionEditorStateEvent(editorState);
    };

    const handleSave = () => {
        const description = stateToHTML(descriptionEditorState.getCurrentContent());

        if (existingField !== null) {
            createFieldFromExistingField(editorState, existingField, currentFields, contentId);
            setIsAddingFromExistingFieldEvent(true);

            return handleOnClose();
        }

        if (updateFieldFromQuestionnaire && selectedField !== undefined) {
            createFieldForQuestionnaire(
                updateFieldFromQuestionnaire,
                selectedField,
                selectedValue,
                multiSelectItems,
                singleSelectItems,
                radioItems,
                question,
                description,
                placeholder,
                contentId
            );

            return handleOnClose();
        }

        if (isAddingFieldFromScratch) {
            setEditorStateEvent({ editorState: addButtonEntityFromScratch(editorState, placeholder), contentId });
        }

        if (isAddingFieldFromSelection) {
            setEditorStateEvent({ editorState: addButtonEntityFromSelection(editorState, placeholder), contentId });
        }

        if (isUpdatingField && selectedField.entityProps !== undefined) {
            setEditorStateEvent({
                editorState: updateButtonEntity(editorState, placeholder, selectedField.entityProps),
                contentId
            });
        }

        switch (selectedValue) {
            case FieldTypesEnum.MultiSelect:
                createMultiselectField(
                    multiSelectItems,
                    selectedField,
                    currentFields,
                    question,
                    description,
                    placeholder,
                    contentId
                );
                break;
            case FieldTypesEnum.Select:
                createSelectField(
                    singleSelectItems,
                    selectedField,
                    currentFields,
                    question,
                    description,
                    placeholder,
                    contentId
                );
                break;
            case FieldTypesEnum.Radio:
                createRadioField(
                    radioItems,
                    selectedField,
                    currentFields,
                    question,
                    description,
                    placeholder,
                    contentId
                );
                break;
            default:
                createTextField(selectedField, currentFields, placeholder, description, question, contentId);
        }

        return handleOnClose();
    };

    const handleOnChangeAnswers = ({
        itemType = FieldTypesEnum.Radio,
        type = 'add',
        index
    }: AddOrRemoveButtonProps) => {
        switch (itemType) {
            case FieldTypesEnum.Radio:
                if (type === 'remove') {
                    return setRadioItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setRadioItems(prevState => {
                    const copyOfState = [...prevState];

                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
            case FieldTypesEnum.Select:
                if (type === 'remove') {
                    return setSingleSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setSingleSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
            case FieldTypesEnum.MultiSelect:
                if (type === 'remove') {
                    return setMultiSelectItems(prevState => {
                        const copyOfState = [...prevState];
                        copyOfState.splice(index, 1);

                        return [...copyOfState];
                    });
                }

                return setMultiSelectItems(prevState => {
                    const copyOfState = [...prevState];
                    copyOfState.splice(index + 1, 0, {
                        type: FieldTypesEnum.Text,
                        id: generateId(),
                        value: '',
                        text: ''
                    });

                    return [...copyOfState];
                });
        }
    };

    const onChangePlaceholder = useCallback(
        ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
            setPlaceholderError(validatePlaceholder(allFieldsSelectOptions, isUpdatingField, value, selectedField));

            setPlaceholderEvent(value);
        },
        [allFieldsSelectOptions, selectedField, isUpdatingField]
    );

    useEffect(() => {
        setQuestionEvent(selectedField?.value || selectedField?.question || '');
    }, [selectedField]);

    useEffect(() => {
        setPlaceholderEvent(passedPlaceholder || selectionText || '');
    }, [passedPlaceholder, selectionText]);

    return (
        <Modal
            customFooter={
                <Footer>
                    <Button
                        disabled={(placeholder.length === 0 && existingField === null) || hasPlaceholderError}
                        onClick={handleSave}
                        variant="primary"
                    >
                        Save
                    </Button>
                </Footer>
            }
            onClose={handleOnClose}
            title="Field"
            width="720px"
            visible
        >
            <Container>
                {(isAddingFieldFromScratch || isAddingFieldFromSelection) && (
                    <NewFieldText>Create a new field</NewFieldText>
                )}

                <Content>
                    <Label>
                        <Input
                            error={getPlaceholderInputError(hasPlaceholderError, isUpdatingField)}
                            placeholder="Enter placeholder here..."
                            disabled={existingField !== null}
                            defaultValue={passedPlaceholder}
                            onChange={onChangePlaceholder}
                            title="Placeholder"
                        />
                        <Tooltip isActivated={ref.current !== null && isEllipsisActive(ref.current)} content={question}>
                            <Input
                                onChange={event => {
                                    setQuestionEvent(event.target.value);
                                }}
                                placeholder="Enter your question here..."
                                disabled={existingField !== null}
                                defaultValue={question}
                                title="Question"
                                reference={ref}
                                isEllipsis
                            />
                        </Tooltip>
                    </Label>
                    <SelectWrapper>
                        <Title>Type</Title>
                        <Select
                            defaultValue={{
                                icon: icons[selectedValue as keyof typeof icons],
                                label: selectedValue,
                                value: selectedValue
                            }}
                            onChange={value => {
                                const newValue = value as SingleValue<SelectValue>;

                                handleSelectChange(newValue?.label || '');
                            }}
                            disabled={existingField !== null}
                            options={inputTypesForField}
                            isSearchable={false}
                        />
                    </SelectWrapper>
                </Content>
                {[FieldTypesEnum.MultiSelect, FieldTypesEnum.Select, FieldTypesEnum.Radio].includes(selectedValue) && (
                    <SelectMultiSelectRadioEditor
                        setItems={
                            {
                                [FieldTypesEnum.MultiSelect]: setMultiSelectItems,
                                [FieldTypesEnum.Select]: setSingleSelectItems,
                                [FieldTypesEnum.Radio]: setRadioItems
                            }[
                                selectedValue as
                                    | FieldTypesEnum.MultiSelect
                                    | FieldTypesEnum.Select
                                    | FieldTypesEnum.Radio
                            ]
                        }
                        items={
                            {
                                [FieldTypesEnum.MultiSelect]: multiSelectItems,
                                [FieldTypesEnum.Select]: singleSelectItems,
                                [FieldTypesEnum.Radio]: radioItems
                            }[
                                selectedValue as
                                    | FieldTypesEnum.MultiSelect
                                    | FieldTypesEnum.Select
                                    | FieldTypesEnum.Radio
                            ]
                        }
                        handleOnChangeAnswers={handleOnChangeAnswers}
                        fieldType={getFieldType(selectedValue)}
                    />
                )}
                <RichTextEditor
                    onEditorStateChange={onDescriptionEditorStateChange}
                    placeholder="Enter an optional explanation..."
                    editorState={descriptionEditorState}
                    disabled={existingField !== null}
                    canAddField={false}
                    forDescription
                />
            </Container>
            {(isAddingFieldFromScratch || isAddingFieldFromSelection) && (
                <>
                    <Hr />
                    <NewFieldText>or insert an existing field</NewFieldText>
                    <AllFieldsSelectWrapper>
                        <Select
                            onChange={value => {
                                const fieldToClone = allFields.find(
                                    item => item.id === (value as SingleValue<SelectValue>)?.value
                                );

                                if (fieldToClone) {
                                    setExistingField(fieldToClone);
                                }
                            }}
                            value={existingField ? { label: existingField.title, value: existingField.id } : null}
                            options={allFieldsSelectOptions}
                        />
                        <CloseIconWrapper
                            onClick={() => {
                                setExistingField(null);
                            }}
                            $disabled={existingField === undefined}
                        >
                            <CloseIcon />
                        </CloseIconWrapper>
                    </AllFieldsSelectWrapper>
                </>
            )}
        </Modal>
    );
};
