import { createEvent, restore } from 'effector';

// * events
export const setQuestionEvent = createEvent<string>();
export const setPlaceholderEvent = createEvent<string>();
export const setIsAddingFromExistingFieldEvent = createEvent<boolean>();

// * stores
export const $question = restore(setQuestionEvent, '');
export const $placeholder = restore(setPlaceholderEvent, '');
export const $isAddingFromExistingField = restore(setIsAddingFromExistingFieldEvent, false);
