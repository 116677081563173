import type { BlockInterface } from 'types/builder/interface';

// import { replaceTextWithOffset } from 'components/builder/RichTextEditor/helpers/replace-text-with-offset';
import { createEvent, createStore, merge } from 'effector';
// import { stateFromHTML } from 'draft-js-import-html';
// import { EditorState } from 'draft-js';

import type {
    AddContentEditorStateEventProps,
    ChangeDocumentNameEventProps,
    DeleteClauseVariationsProps,
    ChangeBlockTitleEventProps,
    ChangeShowClauseEventProps,
    DeleteBlockVariationsProps,
    ChangeShowBlockEventProps,
    AddClauseVariationsProps,
    AddBlockVariationsProps,
    AddParagraphEventProps,
    BlockReorderEventProps,
    FieldReorderEventProps,
    DeleteBlockEventProps,
    InitializeBlocksProps,
    AddBlockEventProps
} from './types';

import {
    addSigningBlockSectionToRowEvent,
    reorderSigningBlockFieldsInGroup,
    deleteSigningBlockSectionEvent,
    addSigningBlockRowEvent,
    addSigningBlockEvent
} from '../signingBlock/model';
import {
    makeClauseVariantIsDefaultEvent,
    deleteClauseVariantEvent,
    changeClauseTitleEvent,
    addClauseVariantEvent,
    deleteClauseEvent,
    addClauseEvent
} from '../clause';
// import { generateDefaultStateForData } from '../field/helpers/generate-default-state-for-data';
import { deleteClauseVariationsReducer } from './reducers/delete-clause-variations-reducer';
import { addContentEditorStateReducer } from './reducers/add-content-editor-state-reducer';
import { addClauseVariationsReducer } from './reducers/add-clause-variations-reducer';
// import { prepareFieldForUpdate } from '../field/helpers/prepare-field-for-update';
import { changeShowClauseReducer } from './reducers/change-show-clause-reducer';
// import { setDescriptionEditorStateEvent } from '../descriptionEditor/model';
import { addParagraphReducer } from './reducers/add-paragraph-reducer';
import { upfrontSummaryEvents } from '../upfrontSummary';
import { questionnaireEvents } from '../questionnaire';
// import { updateFieldEvent } from '../field/model';
import { designEvents } from '../design';

// * events
export const addBlockEvent = createEvent<AddBlockEventProps>();
export const changeBlockTitleEvent = createEvent<ChangeBlockTitleEventProps>();
export const deleteBlockEvent = createEvent<DeleteBlockEventProps>();
export const addParagraphEvent = createEvent<AddParagraphEventProps>();
export const updateAllParagraphsEvent = createEvent<{ contentId: string; clauseId: string; blockId: string }>();
export const blockReorderEvent = createEvent<BlockReorderEventProps>();
export const fieldReorderEvent = createEvent<FieldReorderEventProps>();
export const addBlockVariationsEvent = createEvent<AddBlockVariationsProps>();
export const deleteBlockVariationsEvent = createEvent<DeleteBlockVariationsProps>();
export const addClauseVariationsEvent = createEvent<AddClauseVariationsProps>();
export const deleteClauseVariationsEvent = createEvent<DeleteClauseVariationsProps>();
export const initializeBlocksEvent = createEvent<InitializeBlocksProps>();
export const toggleNumberingEvent = createEvent();
export const changeShowBlockEvent = createEvent<ChangeShowBlockEventProps>();
export const changeShowClauseEvent = createEvent<ChangeShowClauseEventProps>();
export const changeDocumentNameEvent = createEvent<ChangeDocumentNameEventProps>();
export const addContentEditorStateEvent = createEvent<AddContentEditorStateEventProps>();
export const resetBlocksEvent = createEvent();
export const allEvents = merge([
    addBlockEvent,
    changeBlockTitleEvent,
    deleteBlockEvent,
    addParagraphEvent,
    blockReorderEvent,
    fieldReorderEvent,
    addBlockVariationsEvent,
    deleteBlockVariationsEvent,
    addClauseVariationsEvent,
    deleteClauseVariationsEvent,
    initializeBlocksEvent,
    toggleNumberingEvent,
    changeShowBlockEvent,
    changeShowClauseEvent,
    changeDocumentNameEvent,
    questionnaireEvents.addNewSubjectEvent,
    questionnaireEvents.changeSubjectTitleEvent,
    questionnaireEvents.reorderEvent,
    designEvents.changeActiveDesign,
    designEvents.changeDocumentFonts,
    designEvents.changeThemes,
    designEvents.changePalletIndex,
    changeClauseTitleEvent,
    addClauseVariantEvent,
    addClauseEvent,
    deleteClauseEvent,
    deleteClauseVariantEvent,
    makeClauseVariantIsDefaultEvent,
    toggleNumberingEvent,
    addSigningBlockRowEvent,
    addSigningBlockSectionToRowEvent,
    addSigningBlockEvent,
    deleteSigningBlockSectionEvent,
    reorderSigningBlockFieldsInGroup,
    upfrontSummaryEvents.addSectionToRowEvent
]);

// * stores
export const $blocks = createStore<{
    blocks: BlockInterface[];
    documentName: string;
    isChanged: boolean;
}>({ documentName: '', isChanged: false, blocks: [] })
    // .on(updateAllParagraphsEvent, (state, { blockId, clauseId, contentId }) => {
    //     state.blocks.map(block => ({
    //         ...block,
    //         groups: block.groups.map(group => ({
    //             ...group,
    //             fields: group.fields.map(field => ({
    //                 ...field,
    //                 content: field.content.map(content => {
    //                     const currentFields = generateDefaultStateForData(content);

    //                     currentFields.forEach(currentField => {
    //                         const contentState = stateFromHTML(currentField.description || '');
    //                         setDescriptionEditorStateEvent(EditorState.createWithContent(contentState));

    //                         const newField = prepareFieldForUpdate(
    //                             { ...payload, entityProps: currentField.entityProps },
    //                             field.id,
    //                             content.id
    //                         );

    //                         const { entityProps } = currentField;
    //                         const newEditorState = replaceTextWithOffset(
    //                             content.editorState,
    //                             entityProps.blockKey,
    //                             entityProps.start,
    //                             newField.placeholder.length,
    //                             newField.placeholder,
    //                             currentField.placeholder.length
    //                         );

    //                         addContentEditorStateEvent({
    //                             editorState: newEditorState,
    //                             contentId: content.id,
    //                             clauseId: field.id,
    //                             blockId: block.id
    //                         });

    //                         updateFieldEvent({
    //                             fieldId: currentField.id,
    //                             contentId: content.id,
    //                             clauseId: field.id,
    //                             blockId: block.id,
    //                             newField
    //                         });
    //                     });

    //                     return content;
    //                 })
    //             }))
    //         }))
    //     }));

    //     return state;
    // })
    .on(allEvents, state => ({
        ...state,
        isChanged: true
    }))
    .on(changeDocumentNameEvent, (state, { documentName }) => ({ ...state, documentName }))
    .on(initializeBlocksEvent, state => ({
        ...state,
        documentName: '',
        blocks: []
    }))
    .on(addContentEditorStateEvent, addContentEditorStateReducer)
    .on(addBlockEvent, (state, { block }) =>
        state.blocks.length > 0
            ? { ...state, blocks: [...state.blocks, block] }
            : {
                  ...state,
                  documentName: '',
                  blocks: [block]
              }
    )
    .on(toggleNumberingEvent, state => ({
        ...state,
        blocks: state.blocks.map(block => ({
            ...block,
            withNumbering: !block.withNumbering
        }))
    }))
    .on(addBlockVariationsEvent, (state, { blockId, data }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: { ...data }, show: false } : block
        )
    }))
    .on(deleteBlockVariationsEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId ? { ...block, variationsBlock: undefined, show: true } : block
        )
    }))
    .on(deleteClauseVariationsEvent, deleteClauseVariationsReducer)
    .on(addClauseVariationsEvent, addClauseVariationsReducer)
    .on(addParagraphEvent, addParagraphReducer)
    .on(deleteBlockEvent, (state, { blockId }) => ({
        ...state,
        blocks: state.blocks.filter(block => block.id !== blockId)
    }))
    .on(changeBlockTitleEvent, (state, { blockId, title }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, title } : block))
    }))
    .on(blockReorderEvent, (state, { items }) => ({
        ...state,
        blocks: items
    }))
    .on(fieldReorderEvent, (state, { blockId, items }) => ({
        ...state,
        blocks: state.blocks.map(block => (block.id === blockId ? { ...block, groups: [{ fields: items }] } : block))
    }))
    .on(changeShowBlockEvent, (state, { blockId, show }) => ({
        ...state,
        blocks: state.blocks.map(block =>
            block.id === blockId && block.variationsBlock
                ? {
                      ...block,
                      show
                  }
                : block
        )
    }))
    .on(changeShowClauseEvent, changeShowClauseReducer)
    .on(resetBlocksEvent, () => ({
        documentName: '',
        isChanged: false,
        blocks: []
    }));
export const $clauses = $blocks.map(item => item.blocks.flatMap(item => item.groups).flatMap(group => group.fields));
