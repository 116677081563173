import type { Data } from 'stores/builder/field/types';

import { setCurrentFieldsEvent } from 'stores/builder/field/model';
import { FieldTypesEnum } from 'constants/builder/enums';
import { v4 as generateId } from 'uuid';

import type { FieldItem } from '../SelectMultiSelectRadioEditor';

export const createFieldForQuestionnaire = (
    updateFieldFromQuestionnaire: (payload: Data) => void,
    selectedField: Data,
    selectedValue: FieldTypesEnum,
    multiSelectItems: FieldItem[],
    singleSelectItems: FieldItem[],
    radioItems: FieldItem[],
    question: string,
    description: string,
    placeholder: string,
    contentId: string
) => {
    let payload: Data;

    if (selectedValue === FieldTypesEnum.MultiSelect) {
        const generatedMultiSelectItems = multiSelectItems.map(item => ({
            value: item.value,
            id: generateId(),
            text: item.text,
            type: 'Text'
        }));

        payload = {
            multiSelectItems: generatedMultiSelectItems,
            id: selectedField.id,
            type: selectedValue,
            value: question,
            description,
            placeholder,
            contentId
        };
    }

    if (selectedValue === FieldTypesEnum.Select) {
        const generatedSingleSelectItems = singleSelectItems.map(item => ({
            value: item.value,
            id: generateId(),
            text: item.text,
            type: 'Text'
        }));

        payload = {
            singleSelectItems: generatedSingleSelectItems,
            type: FieldTypesEnum.Select,
            id: selectedField.id,
            value: question,
            description,
            placeholder,
            contentId
        };
    }

    if (selectedValue === FieldTypesEnum.Radio) {
        const generatedSingleRadioItems = radioItems.map(item => ({
            value: item.value,
            id: generateId(),
            text: item.text,
            type: 'Text'
        }));

        payload = {
            singleRadioItems: generatedSingleRadioItems,
            type: FieldTypesEnum.Radio,
            id: selectedField.id,
            value: question,
            description,
            placeholder,
            contentId
        };
    }

    payload = {
        id: selectedField.id,
        type: selectedValue,
        placeholder,
        description,
        value: '',
        contentId,
        question
    };

    updateFieldFromQuestionnaire(payload);
    setCurrentFieldsEvent({ currentFields: [{ ...payload, entityProps: selectedField.entityProps }], contentId });
};
