import type { FC } from 'types/react';

import { resetOwnTemplateEvent, updateOwnTemplateFx, $ownTemplate } from 'stores/ownTemplates/model';
import { leaveBuilderModalStore, templateModalStore, draftModalStore } from 'stores/modals/model';
import { questionnaireEvents } from 'stores/builder/questionnaire';
import { resetBlocksEvent } from 'stores/builder/block/model';
import { updateDraftFx, $draft } from 'stores/drafts/model';
import { Button } from 'ui-kit/components/buttons/Button';
import { DRAFTS_LINK, TEMPLATES_LINK } from 'constants/routes';
import { Modal } from 'ui-kit/components/Modal';
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { AreYouSureIcon } from '../AreYouSureModal/static_assets';
import { ButtonsContainer, BodyContainer, Text } from './styles';

export const LeaveBuilderModal: FC = () => {
    const { closeModal, modal } = leaveBuilderModalStore;
    const [_, { isUpdatingTemplate, isUpdatingDraft, documentName, config }] = useUnit(modal);
    const ownTemplate = useUnit($ownTemplate);
    const draft = useUnit($draft);
    const navigate = useNavigate();
    console.log('____is updating template', isUpdatingTemplate);
    console.log('____is updating draft', isUpdatingDraft);
    interface TemplateData {
        templateCategory: string;
        templateName: string;
        templateType: string;
        documentName: string;
        templateConfig: any;
        id?: string; // Optional id property
    }
    const goBackToDashboardTemplates = () => {
        if (isUpdatingTemplate) {
            navigate(TEMPLATES_LINK);
            closeModal();
        }

        if (isUpdatingDraft) {
            navigate(DRAFTS_LINK);
            closeModal();
        }
        resetBlocksEvent();
        questionnaireEvents.resetQuestionnaireEvent();
        resetOwnTemplateEvent();
    };

    const handleSaveTemplate = useCallback(async () => {
        if (ownTemplate) {
            const data = {
                templateName: ownTemplate?.templateName,
                templateCategory: 'Hiring',
                templateType: 'Contract',
                templateConfig: config,
                documentName
            };

            await updateOwnTemplateFx({ id: ownTemplate?.id, data });
            closeModal();
        }
    }, [closeModal, config, documentName, ownTemplate]);
    // for draft
    const handleSaveDraft = useCallback(async () => {
        if (draft) {
            const data: TemplateData = {
                templateName: draft?.draft?.templateName,
                templateCategory: 'Hiring',
                templateType: 'Contract',
                templateConfig: config,
                documentName
            };
            data.id = draft?.id;
            await updateDraftFx(data);
            closeModal();
            navigate(DRAFTS_LINK);
        }
    }, [closeModal, config, documentName, ownTemplate]);

    const handleCreateTemplate = () => {
        closeModal();

        templateModalStore.openModal({ documentName, config });
    };

    // create draft
    const handleCreateDraft = () => {
        closeModal();

        draftModalStore.openModal({ documentName, config });
    };

    return (
        <Modal title={'Unsaved changes'} onClose={closeModal} visible>
            <BodyContainer>
                <AreYouSureIcon />
                <Text>
                    You are about to leave this template without saving. All changes will be lost. Do you really want to
                    discard changes
                </Text>
            </BodyContainer>
            <ButtonsContainer>
                <Button onClick={goBackToDashboardTemplates} variant="secondary">
                    Discard changes
                </Button>
                <Button
                    onClick={
                        isUpdatingTemplate
                            ? handleSaveTemplate
                            : isUpdatingDraft
                              ? handleSaveDraft
                              : draft
                                ? handleCreateTemplate
                                : handleCreateDraft
                    }
                    variant="primary"
                >
                    {isUpdatingTemplate ? 'Save template' : isUpdatingDraft ? 'Save draft' : ''}
                </Button>
            </ButtonsContainer>
        </Modal>
    );
};
