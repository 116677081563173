import ReactSelect, {
    type SingleValueProps,
    type StylesConfig,
    type OptionProps,
    type SingleValue,
    type MultiValue,
    type ActionMeta,
    type GroupBase,
    type Props
} from 'react-select';
import { type FC } from 'types/react';

import { customStyles } from './helpers/get-custom-styles';
import './index.scss';

export interface SelectValue {
    icon?: JSX.Element;
    label: string;
    value: string;
}

interface SelectProps extends Omit<Props, 'onChange' | 'options' | 'styles'> {
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue>,
        actionMeta: ActionMeta<SelectValue>
    ) => void;
    styles?: StylesConfig<SelectValue, boolean, GroupBase<SelectValue>>;
    defaultValue?: SelectValue[] | SelectValue;
    value?: SelectValue[] | SelectValue | null;
    options: SelectValue[];
    disabled?: boolean;
    width?: string;
}

const CustomOption = ({ innerProps, innerRef, data }: OptionProps<SelectValue, boolean, GroupBase<SelectValue>>) => (
    <div className="react-select__option" ref={innerRef} {...innerProps}>
        {data.icon}
        {data.label}
    </div>
);

const CustomSingleValue = ({ innerProps, data }: SingleValueProps<SelectValue, boolean, GroupBase<SelectValue>>) => (
    <div className="react-select__single-value" {...innerProps}>
        {data.icon}
        {data.label}
    </div>
);

export const Select: FC<SelectProps> = ({
    defaultValue,
    isSearchable,
    isClearable,
    placeholder,
    onChange,
    disabled,
    options,
    isMulti,
    styles,
    width,
    value
}) => (
    <ReactSelect
        components={{
            SingleValue: CustomSingleValue,
            Option: CustomOption
        }}
        styles={styles || customStyles(width)}
        className="react-select__container"
        menuPortalTarget={document.body}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPlacement="auto"
        onChange={onChange}
        isMulti={isMulti}
        options={options}
        value={value}
    />
);
