import { descriptionModalStore } from 'stores/modals/model';
import { Tooltip } from 'ui-kit/components/Tooltip';
import { type FC } from 'types/react';

import TooltipIcon from './static_assets/tooltip_icon.svg';
import { StyledCustomImage } from './styles';

interface Props {
    backgroundWidth?: string;
    description: string;
}

export const DescriptionBadge: FC<Props> = ({ backgroundWidth, description }) => {
    const openDescriptionModal = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        descriptionModalStore.openModal({ backgroundWidth: backgroundWidth || '50%', description });
    };

    return (
        <Tooltip content={'Show descriptions'} isActivated>
            <StyledCustomImage
                onClick={e => openDescriptionModal(e)}
                src={TooltipIcon}
                height="20px"
                role="button"
                width="20px"
                pointer
            />
        </Tooltip>
    );
};
