import { sendRequestAccessFx, shareDataStore } from 'stores/drafts/model';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { InputTitle } from 'ui-kit/components/typography/Span';
import { useEffect, useState } from 'react';
import { USER_PREVIEW } from 'constants/routes';
import { Button } from 'ui-kit/components/buttons/Button';
import { shareModalStore } from 'stores/modals/model';
import { Modal } from 'ui-kit/components/Modal';
import { useUnit } from 'effector-react';
import { type FC } from 'types/react';

import { Text } from './styles';

export const ShareModal: FC = () => {
    const { closeModal, modal } = shareModalStore;
    const [loading, setLoading] = useState(false);
    const [draftId, setDraftId] = useState<string | null>(null); // Store draft ID
    const { documentName, comment, emails, files } = useUnit(shareDataStore);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateEmails = (emails: string[]) => {
        if (emails && emails.length > 0) {
            const allValid = emails.every(email => validateEmail(email));
            setIsEmailValid(allValid);
        } else {
            setIsEmailValid(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const draft = queryParams.get('draft');
        if (draft) {
            setDraftId(draft);
        }
        // Validate emails when modal is opened
        validateEmails(emails);
    }, [emails]);

    const handleShareDocument = async () => {
        if (!draftId) {
            console.error('Draft ID not found in the URL');
            return;
        }
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('draft', draftId);
            formData.append('comment', comment || '');
            formData.append('documentName', documentName || '');

            if (Array.isArray(emails)) {
                emails.forEach(email => formData.append('email[]', email));
            } else {
                formData.append('email', emails);
            }

            // // Append files
            if (Array.isArray(files)) {
                files.forEach(file => formData.append('files', file));
            } else {
                formData.append('files', files);
            }
            formData.forEach((value, key) => {
                console.log(key, value);
            });
            const link = USER_PREVIEW;
            formData.append('link', link);
            sendRequestAccessFx({ formData: formData, id: draftId });
            closeModal(); // Close modal after success
        } catch (error) {
            console.error('Error sharing document:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal title={'Share document'} onClose={closeModal} width="530px" visible>
            <Column gap="24px">
                <InputTitle>{'Send document?'}</InputTitle>
                <Text>Are you sure you want to send the document?</Text>
                <Row width="100%" justifyEnd gap="16px">
                    <Button onClick={() => closeModal()} variant="secondary">
                        No
                    </Button>
                    <Button
                        disabled={!isEmailValid || loading} // Disable if emails are invalid or button is loading
                        onClick={handleShareDocument}
                        variant="primary"
                    >
                        {loading ? 'Sharing...' : 'Yes'}
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};
