import { type ButtonHTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { type FC } from 'types/react';

import {
    StyleChangeDocumentButton,
    StyledShowCommentButton,
    StyledAddEmailButton,
    StyledMessageButton,
    StyledButton,
    ButtonContainer
} from './styles';

export type ButtonVariant = 'secondary' | 'primary' | 'light' | 'link';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: ButtonVariant;
}

// email add button wrapper
const IconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.67px;
    border: 2px solid #0014cb;
    border-radius: 3px;
    width: 15px;
    height: 15px;
`;
// Chat  button wrapper
const MessageIconWrapper = styled.div<{ isActive: boolean }>`
    padding: 9px;
    border-radius: 3px;
    width: 44px;
    height: 44px;
    background-color: ${({ isActive }) => (isActive ? 'blue' : 'transparent')}; /* Blue background when active */
    color: ${({ isActive }) => (isActive ? 'white' : 'black')}; /* White color when active */

    &:hover {
        background-color: blue; /* Background color on hover */
        color: white; /* Icon color on hover */
    }
`;

// SVG  chat icon
const ChatIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        fill="currentColor" // This allows the color to inherit from the paren
        height="24"
        width="24"
    >
        <path
            d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9l.3-.5z"
            fillRule="evenodd"
        />
    </svg>
);
// SVG icon or use an icon from a library
const PlusIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        height="14"
        fill="blue" // This allows the color to inherit from the parent
        width="14"
    >
        <path
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z"
            fillRule="evenodd" // Correcting the attribute to camelCase for React
        />
    </svg>
);
export const uploadIcon = () => {
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="bi bi-upload"
        fill="currentColor"
        viewBox="0 0 16 16"
        height="16"
        width="16"
    >
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
    </svg>;
};

export const Button: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledButton disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledButton>
);

export const AddEmailButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledAddEmailButton disabled={disabled} variant={variant} {...rest}>
        <IconWrapper>
            <PlusIcon />
        </IconWrapper>
        <span style={{ marginLeft: '5px' }}>{children}</span>
    </StyledAddEmailButton>
);

// Chat Icon Button
export const MessageButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => {
    const [isActive, setIsActive] = useState(false); // State to manage active status
    const handleClick = () => {
        setIsActive(prev => !prev); // Toggle active state on click
        console.log('_-set is', setIsActive);
    };
    return (
        <StyledMessageButton onClick={handleClick} disabled={disabled} variant={variant} {...rest}>
            <MessageIconWrapper isActive={isActive}>
                <ChatIcon />
            </MessageIconWrapper>
            {/* {children} */}
            <span>{children}</span>
        </StyledMessageButton>
    );
};

// Show comment Button
export const ShowCommentButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyledShowCommentButton disabled={disabled} variant={variant} {...rest}>
        {children}
    </StyledShowCommentButton>
);

//Change Documents
export const ChangeDocumentButton: FC<ButtonProps> = ({ children, disabled, variant, ...rest }) => (
    <StyleChangeDocumentButton disabled={disabled} variant={variant} {...rest}>
        <ButtonContainer>{children}</ButtonContainer>
    </StyleChangeDocumentButton>
);
